import request from '../utils/request';

export const getExchangeRecordByUser = id => {
    return request({
        url: process.env.VUE_APP_URL + `record/getExchangeRecordByUser`,
        method: 'get',
        params: {
            id
        }
    });
};

export const addExchangeRecord = (data) => {
    return request({
        url: process.env.VUE_APP_URL + `record/addExchangeRecord`,
        method: 'post',
        data: data
    });
}
