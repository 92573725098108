<template>
    <div style="padding-top: 20px;">
        <div class="record-header">
            <img class="back-arrow" src="/images/Exchange/Arrowhead_Left.png" @click="exit"/>
            <div class="record-title">兑换记录</div>
        </div>
        <div class="record-list">
            <div v-for="(item, index) in exchangeRecords" :key="index">
                <div class="record-item">
                    <div class="record-item-top">
                        <div class="record-item-image-box">
                            <img class="record-item-image" :src="item.thumbnail && item.thumbnail.length > 0 ? item.thumbnail : '/images/Exchange/detail_banner.png'" />
                        </div>
                        <div class="record-item-details">
                            <div class="record-item-name"> {{ item.commodityName }}</div>
                            <div class="record-item-price"> {{ item.commodityPrice }} 贡献值</div>
                            <div class="record-item-others">
                                <div class="record-item-spec"> 规格： {{ item.specName }}</div>
                                <div class="record-item-num"> 数量：{{ item.number }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="record-divide"></div>
                    <div class="record-item-bottom">
                        <div class="record-item-total-price">
                            支付贡献值 <span style="color: #81c4f5;">{{ item.price }}</span>
                        </div>
                        <div class="record-item-btn" @click="checkExpressNum(index)">
                            物流单号
                        </div>
                    </div>
                </div>
            </div>
            
            <van-overlay :show="overlayShow">
                <div class="express-overlay">
                    <div class="express-num-box">
                        <div class="icon-box"><img class="bag-icon" src="/images/Exchange/bag_icon.png" /></div>
                        <div class="express-info-text">快递：{{ exchangeRecords.length > 0 && exchangeRecords[selectedId].expressName.length > 0 ? exchangeRecords[selectedId].expressName : '暂无' }}</div>
                        <div class="express-info-text">单号：{{ exchangeRecords.length > 0 && exchangeRecords[selectedId].expressNum.length > 0 ? exchangeRecords[selectedId].expressNum : '暂无' }}</div>
                        <img class="close-icon" src="/images/Exchange/Close.png" @click="overlayShow = false" />
                    </div>
                </div>
            </van-overlay>
        </div>
    </div>
</template>

<script>
    import { Overlay, Notify } from 'vant';
    import { getExchangeRecordByUser } from "@/api/ExchangeRecord";
    export default {
        name: "ExchangeRecord",
        data() {
            return {
                exchangeRecords: [],
                overlayShow: false,
                selectedId: 0
            }; 
        },
        components: {
            'van-overlay': Overlay
        },
        mounted() {
            this.getExchangeRecord();
        },
        methods: {
            exit(){
                this.$router.go(-1)
            },
            checkExpressNum(idx) {
                this.selectedId = idx;
                this.overlayShow = true;
            },
            async getExchangeRecord() {
                try {
                    let info = this.$store.state.user.info;
                    let {errorCode, payload} = await getExchangeRecordByUser(info.id);
                
                    if (errorCode == 200) {
                        this.exchangeRecords = payload;
                    } else {
                        Notify({type: "danger", message:'获取兑换记录失败'});
                    }
                } catch (err) {
                    console.log(err);
                    Notify({type: "danger", message:'获取兑换记录失败'});
                }
            }
        },
    }
</script>

<style scoped>
    .record-header {
        text-align: center;
        position: relative;
        margin: 0 auto;
    }

    .back-arrow {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
    }

    .record-title {
        font-size: 18px;
        color: #fff;
        font-weight: bold;
    }

    .record-list {
        width: 90%;
        margin: 20px auto;
        height: calc(100vh - 135px);
        overflow: scroll;
    }

    .record-item-top {
        display: flex;
        justify-content: center;
    }

    .record-item {
        width: 100%;
        background: #21242d;
        padding: 15px 20px;
        box-sizing: border-box;
        border-radius: 20px;
        margin: 15px auto;
    }
    
    .record-item-image-box {
        width: 45%;
    }

    .record-item-image {
        width: 120px;
        height: 80px;
    }

    .record-item-details {
        width: 55%;
        height: 80px;
    }

    .record-item-others {
        display: flex;
        margin-top: 20px;
    }

    .record-item-name {
        color: white;
        font-size: 18px;
        font-weight: bolder;
        line-height: 25px;
    }

    .record-item-price {
        color: #6149df;
        font-size: 14px;
        line-height: 20px;
    }

    .record-item-spec {
        color: #747070;
        font-size: 12px;
        width: 70%;

    }

    .record-item-num {
        color: #747070;
        font-size: 12px;
        width: 50%;
        text-align: right;
    }

    .record-divide {
        width: 100%;
        height: 1px;
        background: black;
        margin: 10px auto;
    }

    .record-item-bottom {
        display: flex;
    }

    .record-item-total-price {
        font-size: 12px;
        color: white;
        height: 30px;
        line-height: 30px;
        width: 80%;
    }

    .record-item-btn {
        font-size: 12px;
        color: white;
        cursor: pointer;
        background: linear-gradient(270deg,#81c4f5,#6149df);
        height: 30px;
        border-radius: 30px;
        width: 80px;
        text-align: center;
        line-height: 30px;
    }

    .express-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .express-num-box {
        position: relative;
        background: linear-gradient(270deg,#81c4f5,#6149df);
        height: 120px;
        width: 70%;
        border-radius: 30px;
        padding: 20px 40px 10px 40px;
        box-sizing: border-box;
        overflow: scroll;
    }

    .icon-box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }

    .bag-icon {
        width: 30px;
    }

    .express-info-text {
        color: white;
        font-size: 14px;
        text-align: left;
        font-weight: 500;
    }
    
    .close-icon {
        position: absolute;
        right: 15px;
        top: 10px;
        width: 30px;
    }
</style>